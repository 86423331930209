<template>
    <span class="text-nowrap">
        <Icon :v="niceLink.icon" :set="niceLink.iconSet || 'l'" />
        <a :href="linkTrimmed" target="_blank" rel="me noopener" class="link-ellipsis">{{ niceLink.text }}</a>
        <button v-if="verifiedBy" class="btn btn-sm p-0" @click="verificationInfo">
            <Icon v="shield-check" set="s" class="small text-primary" />
        </button>
    </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import useDialogue from '../composables/useDialogue.ts';
import useLinkUtils from '../composables/useLinkUtils.ts';
import type { Metadata, NiceLink } from '../composables/useLinkUtils.ts';

export default defineComponent({
    props: {
        link: { required: true, type: String },
        metadata: { type: Object as PropType<Metadata> },
        expand: { type: Boolean },
        verifiedLinks: {
            type: Object as PropType<Record<string, string>>,
            default: (): Record<string, string> => {
                return {};
            },
        },
    },
    setup() {
        const { beautifyLink } = useLinkUtils();
        return {
            dialogue: useDialogue(),
            beautifyLink,
        };
    },
    computed: {
        linkTrimmed(): string {
            return this.link.trim();
        },
        niceLink(): NiceLink {
            return this.beautifyLink(this.linkTrimmed, this.expand, this.verifiedBy, this.metadata);
        },
        verifiedBy(): string {
            return this.verifiedLinks[this.link];
        },
    },
    methods: {
        async verificationInfo() {
            await this.dialogue.alert({
                icon: 'shield-check',
                header: this.$t('profile.verifiedLinks.header'),
                message: this.$t('profile.verifiedLinks.info'),
            });
        },
    },
});
</script>

<style lang="scss" scoped>
    .icon {
        height: 1em;
    }
    .link-ellipsis {
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        line-height: 1em;
        vertical-align: middle;
    }
</style>
